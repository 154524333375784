/* ============== common ============== */
* {
    margin: 0px;
    padding: 0;
}

html, body {
    //overflow: hidden;
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

#blocker{
    position:absolute;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.925);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
    
   
}
.selections{
    margin-top: -50px;
    z-index: 1000;
    background-color: rgb(10, 10, 10);
    padding: 50px;
    border-radius: 50px;
    //box-shadow: 0 16px 16px 0 rgba(255, 255, 255, 0.3);
    width: 60%;
    align-self: center;

}
#markers{
    position:absolute;
    top: 20px;
    right: 20px;
    color: white;
    background-color: rgba(148, 179, 253, 0.66);
    border-radius: 25px;
    max-width: 20%;
    padding: 10px;
}

#markers:hover{
    background-color: rgba(148, 179, 253, 0.91);
}

#markers-title{
    font-size: 20px;
    text-align: right;
}

#markers-content{
    display: none;
    max-height: 300px;
}

#markers-title:hover ~ #markers-content {
    display: block;
}

#markers-content:hover{
    display: block;
}

#current-object{
    position:absolute;
    left: 50%;
    bottom: 20px;
    color: white;
    background-color: rgba(148, 179, 253, 0.66);
    border-radius: 25px;
    max-width: 20%;
    min-width: 100px;
    min-height: 40px;
    padding: 10px;
}

#snackbar, #snackbar-save, #snackbar-import {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: #91c59f;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
}

#snackbar.show, #snackbar-save.show, #snackbar-import.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}
/* ============ END common ============ */

/* ============== home ============== */
#form-content{
    margin-top: 20vh;
    min-height: 600px;
    margin-left: 20%;
    margin-right: 20%;
    padding: 50px;
    align-content: center;
    background-color: white;
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.1);
    display:flex;
    flex-direction: column;
    border-radius: 25px;
}

#client{
    display: none;
    align-content: center;
    margin: auto;
    max-width: 50%;
    margin-top: 3%;
}

#archi{
    display: none;
    flex-direction: column;
    align-content: center;
    padding: 100px;
    max-width: 100%;
    height: 100%!important;
    background-image: linear-gradient(to bottom right,rgba(103, 135, 150, 0.047),rgba(182, 232, 221, 0.112));
    padding-bottom: 200px;
 }
.input{
    background-color: white;
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.1);
    margin: 25px 0 25px 0;
    padding: 30px;
    border-radius: 20px;
    }   
.select{
    display: flex;
    flex-direction: row;
    gap: 50px;
}  

.card01{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 18px;
        margin-bottom: 80px;
        background-color: white;
        box-shadow: 0 16px 16px 0 rgb(0 0 0 / 30%);
        border-radius: 30px;
        justify-content: space-between;
    }
.buttondark{
    background-color: rgb(27, 36, 36);
    height: 35px;
    width: 200px;
    box-shadow: 0 16px 16px 0 rgba(0,0,0,0.1);
    color: white;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    align-items: center;
    align-self: center;
   padding-top: 5px;
    border-radius: 10px;
}

/* ============ END home ============ */

/* ============== mapping ============== */
#params{
    position:absolute;
    top: 20px;
    left: 20px;
    color: white;
    background-color: rgba(148, 179, 253, 0.66);
    border-radius: 25px;
    max-width: 20%;
    padding: 10px;
}

#params:hover{
    background-color: rgba(148, 179, 253, 0.91);
}

#params-title{
    font-size: 20px;
}

#params-content{
    display: none;
}

#params-title:hover ~ #params-content {
    display: block;
}

#params-content:hover{
    display: block;
}

#models{
    position:absolute;
    left: 50%;
    top: 20px;
    color: white;
    background-color: rgba(148, 179, 253, 0.66);
    border-radius: 25px;
    max-width: 20%;
    padding: 10px;
}

#models:hover{
    background-color: rgba(148, 179, 253, 0.91);
}

#models-title{
    font-size: 20px;
    text-align: right;
}

#models-content{
    display: none;
    max-height: 300px;
}

#models-title:hover ~ #models-content {
    display: block;
}

#models-content:hover{
    display: block;
}

#export{
    position:absolute;
    bottom: 20px;
    right: 20px;
    color: white;
    border-radius: 25px;
    max-width: 20%;
    padding: 10px;
}

#add-model{
    position:absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    border-radius: 25px;
    max-width: 20%;
    padding: 10px;
}
/* ============ END mapping ============ */



